// Generated file, do not edit.
// Edit the json files in the designTokens directory and run the build-design-tokens.js script.

export default {
    optionsBrandColorBlack: '#000000',
    optionsBrandColorWhite: '#FFFFFF',
    optionsBrandColorBlue: '#4e6f8e',
    optionsBrandColorLightBlue: '#718ba4',
    optionsBrandColorGreen: '#52675b',
    optionsBrandColorLightGreen: '#74857b',
    optionsBrandColorOliveGray: '#809388',
    optionsBrandColorRed: '#a54745',
    optionsBrandColorLightRed: '#b76b6a',
    optionsBrandColorYellow: '#d88933',
    optionsBrandColorLightYellow: '#dfa05b',
    optionsBrandColorPurple: '#47384d',
    optionsBrandColorLightPurple: '#6b5f70',
    optionsBrandColorLightGrey: '#f8f8f8',
    optionsBrandColorTransparentWhite: 'rgba(255, 255, 255, .3)',
    optionsNeutralColorBlack: '#191717',
    optionsNeutralColorGrey900: '#000000',
    optionsNeutralColorGrey800: '#000000',
    optionsNeutralColorGrey750: '#000000',
    optionsNeutralColorGrey700: '#000000',
    optionsNeutralColorGrey600: '#4F4F4F',
    optionsNeutralColorGrey500: '#999999',
    optionsNeutralColorGrey400: '#A8A8A8',
    optionsNeutralColorGrey300: '#D1D4D2',
    optionsNeutralColorGrey200: '#E3E3E3',
    optionsNeutralColorGrey100: '#F5F4F1',
    optionsNeutralColorGrey50: '#F5F4F1',
    optionsNeutralColorWhite: '#FFFFFF',
    optionsNeutralColorLightGrey: '#b5b5b5',
    optionsFormColorCheck: '#007B4D',
    optionsStatusColorSuccessLight: '#F0FDF5',
    optionsStatusColorSuccessMain: '#21A153',
    optionsStatusColorSuccessDark: '#21A153',
    optionsStatusColorWarningLight: '#FEFCE9',
    optionsStatusColorWarningMain: '#ffb75d',
    optionsStatusColorWarningDark: '#975A12',
    optionsStatusColorErrorLight: '#FEF3F2',
    optionsStatusColorErrorMain: '#c23934',
    optionsStatusColorErrorDark: '#AE201A',
    optionsStatusColorInfoLight: '#EFF6FF',
    optionsStatusColorInfoMain: '#0073BE',
    optionsStatusColorInfoDark: '#1244CA',
    optionsFontFamilySansSerifOne: "'Karmina Sans', sans-serif",
    optionsFontFamilySansSerifTwo: "'Bree', sans-serif",
    optionsFontWeightLight: '300',
    optionsFontWeightRegular: '400',
    optionsFontWeightMedium: '500',
    optionsFontWeightBold: '600',
    optionsTextTransformNormal: 'none',
    optionsTextTransformLowercase: 'lowercase',
    optionsTextTransformUppercase: 'uppercase',
    optionsLetterSpacingNormal: '0',
    optionsLetterSpacingWide: '.075em',
    optionsLetterSpacingMediumWide: '.1em',
    optionsLetterSpacingExtraWide: '.125em',
    optionsMediaQueryXs: 576,
    optionsMediaQueryS: 768,
    optionsMediaQueryM: 992,
    optionsMediaQueryL: 1200,
    optionsMediaQueryXl: 1600,
    optionsMotionEaseOut: 'cubic-bezier(0, 0, 0.25, 1)',
    optionsMotionEaseIn: 'cubic-bezier(0.50, 0, 1, 1)',
    optionsMotionEaseInOut: 'cubic-bezier(0.25, 0.75, 0.25, 0.75)',
    additionalMotionEaseOut: 'cubic-bezier(0, 0, 0.25, 1)',
    additionalMotionEaseIn: 'cubic-bezier(0.50, 0, 1, 1)',
    additionalMotionEaseInOut: 'cubic-bezier(0.25, 0.75, 0.25, 0.75)',
    additionalCarouselMixBlendMode: 'unset',
    additionalSmartfitCtaTextColor: '#FFFFFF',
    additionalSmartfitPrimaryColor: '#a54745',
    additionalProductDescriptionTextColor: '#191717',
    additionalProductDescriptionBackgroundColor: '#F5F4F1',
    additionalKeyFeatureListSupertitleBorderColor: '#a54745',
    breakpointExtraSmall: 576,
    breakpointSmall: 768,
    breakpointMedium: 992,
    breakpointLarge: 1200,
    breakpointExtraLarge: 1600,
    fontTitleFamily: "'Bree', sans-serif",
    fontTitleWeight: '500',
    fontTitleTransform: 'none',
    fontTitleLetterSpacing: '0',
    fontTitleHighlightFamily: "'Bree', sans-serif",
    fontTitleHighlightWeight: '500',
    fontTitleHighlightTransform: 'none',
    fontTitleHighlightLetterSpacing: '0',
    fontHeadlineOneFamily: "'Bree', sans-serif",
    fontHeadlineOneWeight: '500',
    fontHeadlineOneTransform: 'none',
    fontHeadlineOneLetterSpacing: '0',
    fontHeadlineTwoFamily: "'Bree', sans-serif",
    fontHeadlineTwoWeight: '500',
    fontHeadlineTwoTransform: 'none',
    fontHeadlineTwoLetterSpacing: '0',
    fontHeadlineThreeFamily: "'Bree', sans-serif",
    fontHeadlineThreeWeight: '500',
    fontHeadlineThreeTransform: 'none',
    fontHeadlineThreeLetterSpacing: '0',
    fontSubHeadlineFamily: "'Karmina Sans', sans-serif",
    fontSubHeadlineWeight: '500',
    fontSubHeadlineTransform: 'none',
    fontSubHeadlineLetterSpacing: '0',
    fontTitleOverlayFamily: "'Karmina Sans', sans-serif",
    fontTitleOverlayWeight: '400',
    fontTitleOverlayTransform: 'none',
    fontTitleOverlayLetterSpacing: '0',
    fontMainNavItemFamily: "'Karmina Sans', sans-serif",
    fontMainNavItemWeight: '600',
    fontMainNavItemTransform: 'none',
    fontMainNavItemLetterSpacing: '0',
    fontBodyFamily: "'Karmina Sans', sans-serif",
    fontBodyWeight: '400',
    fontBodyTransform: 'none',
    fontBodyLetterSpacing: '0',
    fontCaptionFamily: "'Karmina Sans', sans-serif",
    fontCaptionWeight: '400',
    fontCaptionTransform: 'none',
    fontCaptionLetterSpacing: '0',
    fontButtonFamily: "'Karmina Sans', sans-serif",
    fontButtonWeight: '400',
    fontButtonWeightSmall: '400',
    fontButtonTransform: 'none',
    fontButtonLetterSpacing: '0',
    fontLinkFamily: "'Karmina Sans', sans-serif",
    fontLinkWeight: '400',
    fontLinkTransform: 'none',
    fontLinkLetterSpacing: '0',
    fontPriceFamily: "'Bree', sans-serif",
    fontPriceWeight: '400',
    fontPriceTransform: 'none',
    fontPriceLetterSpacing: '0',
    fontHeroTitleLetterSpacing: '0',
    fontLineHeightExtraSmall: '.95',
    fontLineHeightSmall: '1',
    fontLineHeightMedium: '1',
    fontLineHeightLarge: '1.2',
    fontPlpCategoryLabelsTextTransform: 'none',
    fontPlpCategoryLabelsFontWeight: '400',
    fontPlpUpillSortingTextTransform: 'none',
    fontPlpUpillSortingFontWeight: '400',
    sizeTextHeadlineSizeOneSmall: '3.2rem',
    sizeTextHeadlineSizeOneMedium: '4.2rem',
    sizeTextHeadlineSizeTwoSmall: '2.4rem',
    sizeTextHeadlineSizeTwoMedium: '3.6rem',
    sizeTextHeadlineSizeThreeSmall: '2.2rem',
    sizeTextHeadlineSizeThreeMedium: '2.8rem',
    sizeTextHeadlineSizeFourSmall: '2rem',
    sizeTextHeadlineSizeFourMedium: '2.2rem',
    sizeTextHeadlineSizeFiveSmall: '1.8rem',
    sizeTextHeadlineSizeFiveMedium: '2rem',
    sizeTextHeadlineSizeSixSmall: '1.4rem',
    sizeTextHeadlineSizeSixMedium: '1.6rem',
    sizeTextOverlayTitleExtraSmall: 'calc((1rem + 0.25vw) * 2.4)',
    sizeTextOverlayTitleSmall: 'calc((1rem + 0.25vw) * 3.2)',
    sizeTextOverlayTitleMedium: '6.4rem',
    sizeTextOverlayTitleLarge: '6.4rem',
    sizeTextBodyExtraSmall: '1.2rem',
    sizeTextBodySmall: '1.6rem',
    sizeTextBodyMedium: '1.6rem',
    sizeTextBodyLarge: '1.8rem',
    sizeTextBodyExtraLarge: '2.4rem',
    sizeGridSpacingBlockMedium: '4rem',
    sizeGridSpacingBlockLarge: '8rem',
    sizeGridSpacingRem2: '0.2rem',
    sizeGridSpacingRem4: '0.4rem',
    sizeGridSpacingRem6: '0.6rem',
    sizeGridSpacingRem8: '0.8rem',
    sizeGridSpacingRem10: '1rem',
    sizeGridSpacingRem12: '1.2rem',
    sizeGridSpacingRem14: '1.4rem',
    sizeGridSpacingRem16: '1.6rem',
    sizeGridSpacingRem20: '2rem',
    sizeGridSpacingRem24: '2.4rem',
    sizeGridSpacingRem28: '2.8rem',
    sizeGridSpacingRem32: '3.2rem',
    sizeGridSpacingRem36: '3.6rem',
    sizeGridSpacingRem40: '4rem',
    sizeGridSpacingRem44: '4.4rem',
    sizeGridSpacingRem48: '4.8rem',
    sizeGridSpacingRem52: '5.2rem',
    sizeGridSpacingRem56: '5.6rem',
    sizeGridSpacingRem60: '6rem',
    sizeGridSpacingRem64: '6.4rem',
    sizeGridLayoutRem24: '2.4rem',
    sizeGridLayoutRem32: '3.2rem',
    sizeGridLayoutRem40: '4rem',
    sizeGridLayoutRem48: '4.8rem',
    sizeGridLayoutRem56: '5.6rem',
    sizeGridLayoutRem64: '6.4rem',
    sizeGridLayoutRem72: '7.2rem',
    sizeGridLayoutRem80: '8rem',
    sizeGridLayoutRem88: '8.8rem',
    sizeGridLayoutRem96: '9.6rem',
    sizeGridLayoutRem104: '10.4rem',
    sizeGridLayoutRem120: '12rem',
    sizeGridLayoutMaxWidthXxs: '32rem',
    sizeGridLayoutMaxWidthXs: '37.5rem',
    sizeGridLayoutMaxWidthS: '37.5rem',
    sizeGridLayoutMaxWidthM: '61.6rem',
    sizeGridLayoutMaxWidthL: '86.4rem',
    sizeGridLayoutMaxWidthXl: '160rem',
    sizeGridLayoutMaxWidthXxl: '92vw',
    sizeOtherIconXxs: '1.2rem',
    sizeOtherIconXs: '1.6rem',
    sizeOtherIconS: '2rem',
    sizeOtherIconM: '2.4rem',
    sizeOtherIconL: '3.2rem',
    sizeOtherIconXl: '4rem',
    sizeOtherBorderRadiusS: '0rem',
    sizeOtherBorderRadiusM: '0rem',
    sizeOtherBorderRadiusL: '0.8rem',
    sizeOtherBorderRadiusFull: '99rem',
    sizeOtherBorderRadiusButton: '0rem',
    sizeOtherBorderWidthS: '0.1rem',
    sizeOtherBorderWidthXs: '0.1rem',
    sizeOtherLogoWidth: '14.2rem',
    sizeOtherLogoHeight: '1.7rem',
    svgRatingsStarPath:
        'M9.44,0l-2.4,6.96L0,6.881l5.84,4.48L3.6,18l6.08-4.24L15.28,18l-2.08-7.04,5.76-4.08-7.44-.16Z',
    svgRatingsStarViewBox: '0 0 18.96 18',
    svgRatingsStarRatedColor: '#191717',
    svgRatingsStarEmptyColor: '#E3E3E3',
    svgRatingsStarWidgetSpacing: '0.2rem',
    svgQuoteIconPath:
        'M21.1337 24.57C23.3177 23.394 25.0397 22.092 26.2997 20.664C27.5597 19.152 28.1897 17.43 28.1897 15.498C28.1897 14.322 27.9797 13.44 27.5597 12.852C27.2237 12.18 26.8037 11.592 26.2997 11.088C25.8797 10.5 25.4597 9.912 25.0397 9.324C24.7037 8.652 24.5357 7.686 24.5357 6.426C24.5357 4.494 25.1657 2.94 26.4257 1.764C27.7697 0.588 29.4917 0 31.5917 0C33.8597 0 35.7077 0.798002 37.1357 2.394C38.6477 3.99 39.4037 6.3 39.4037 9.324C39.4037 11.76 38.8997 14.028 37.8917 16.128C36.9677 18.144 35.7497 19.992 34.2377 21.672C32.8097 23.352 31.2137 24.822 29.4497 26.082C27.6857 27.342 26.0057 28.392 24.4097 29.232L21.1337 24.57ZM0.595703 24.57C2.7797 23.394 4.5017 22.092 5.7617 20.664C7.0217 19.152 7.6517 17.43 7.6517 15.498C7.6517 14.322 7.4417 13.44 7.0217 12.852C6.6857 12.18 6.2657 11.592 5.7617 11.088C5.3417 10.5 4.9217 9.912 4.5017 9.324C4.1657 8.652 3.9977 7.686 3.9977 6.426C3.9977 4.494 4.6277 2.94 5.8877 1.764C7.2317 0.588 8.9537 0 11.0537 0C13.3217 0 15.1697 0.798002 16.5977 2.394C18.1097 3.99 18.8657 6.3 18.8657 9.324C18.8657 11.76 18.3617 14.028 17.3537 16.128C16.4297 18.144 15.2117 19.992 13.6997 21.672C12.2717 23.352 10.6757 24.822 8.9117 26.082C7.1477 27.342 5.4677 28.392 3.8717 29.232L0.595703 24.57Z',
    svgQuoteIconViewBox: '0 0 40 30',
    svgQuoteIconWidth: 40,
    svgQuoteIconHeight: 30,
    svgQuoteIconFill: '#a54745',
    svgMyAccountPath:
        'M5.517,6.252q.338.364.628.7l4.656.549,5.63,2.072V14.16c0,1.871-5.61,3.339-7.184,3.7L4.83,16.236c-.193.237-.364.449-.5.623L5,18.628,6.462,21.6c3.514-.962,10.711-2.552,13.606-4.572,2.76-1.926,3.481-5.095,2.962-8.277-1.037-6.358-3.978-7.509-10.157-7.305C9.666,1.546,4.617,3,1.529,4c0,.247.035.385.035.493,1.935.039,2.035.113,3.97.176M7.29,8.525a4.555,4.555,0,0,1,.663,1.95c.141,1.782-1.079,3.317-2.161,4.6L9.341,16.38c1.037-.248,5.665-1.4,5.665-2.221V10.57L10.469,8.9,7.29,8.525Zm-3.2-2.54c-.961-.031-1.915-.076-2.878-.076a5.252,5.252,0,0,1-1.07-.044C.1,5.143,0,3.908,0,3.145c0-.12.653-.361,1.095-.5C4.325,1.6,9.468.131,12.828.02c6.923-.228,10.454,1.438,11.606,8.5.61,3.743-.324,7.418-3.552,9.67C17.39,20.624,10,22.1,5.7,23.276L2.535,16.828c1.164-1.5,4.136-4.474,4-6.241C6.424,9.243,4.979,7.752,4.091,6.813V5.985Z',
    svgMyAccountViewBox: '0 0 24.609 23.275',
    svgMyAccountWidth: 24.609,
    svgMyAccountHeight: 23.275,
    svgMyAccountFill: 'currentColor',
    svgCartPath:
        'M19.896,7.79423077 L15.44,0 L13.36,1.18730769 L17.136,7.79423077 L6.864,7.79423077 L10.64,1.18730769 L8.56,0 L4.104,7.79423077 L0,7.79423077 L0,10.2173077 L1.44,10.2173077 L3.632,20.0630769 C3.75840467,20.611453 4.24233195,20.9996402 4.8,21 L19.2,21 C19.7576681,20.9996402 20.2415953,20.611453 20.368,20.0630769 L22.56,10.2011538 L24,10.2011538 L24,7.77807692 L19.896,7.79423077 Z M8.4,17.3976923 L6,17.3976923 L6,11.3965385 L8.4,11.3965385 L8.4,17.3976923 Z M13.2,17.3976923 L10.8,17.3976923 L10.8,11.3965385 L13.2,11.3965385 L13.2,17.3976923 Z M18,17.3976923 L15.6,17.3976923 L15.6,11.3965385 L18,11.3965385 L18,17.3976923 Z',
    svgCartViewBox: '0 0 24 21',
    svgCartWidth: 24,
    svgCartHeight: 21,
    svgCartFill: 'currentColor',
    svgSearchPath:
        'M12.8966 13.282C10.2895 15.8892 6.0625 15.8892 3.45536 13.282C0.848216 10.6749 0.848215 6.44787 3.45535 3.84073C6.06249 1.23359 10.2895 1.23359 12.8966 3.84073C15.5038 6.44787 15.5038 10.6749 12.8966 13.282ZM13.9085 14.3911C10.7118 17.5355 5.57139 17.5194 2.3947 14.3427C-0.798231 11.1498 -0.798232 5.973 2.39469 2.78007C5.58762 -0.412856 10.7644 -0.412856 13.9573 2.78007C16.805 5.62775 17.1129 10.0534 14.881 13.2424L25.1082 23.4696L24.0476 24.5302L13.9085 14.3911Z',
    svgSearchViewBox: '0 0 26 25',
    svgSearchWidth: 26,
    svgSearchHeight: 25,
    themeColorsPrimaryHeaderNavLink: '#FFFFFF',
    themeColorsPrimaryHeaderNavLinkHover: '#E3E3E3',
    themeColorsPrimaryHeaderNavLinkActive: '#E3E3E3',
    themeColorsPrimaryHeaderNavLinkBackground: '#a54745',
    themeColorsPrimaryHeaderNavLinkHoverBackground: '#a54745',
    themeColorsPrimaryHeaderNavLinkActiveBackground: '#a54745',
    themeColorsPrimaryBackgroundColor: '#a54745',
    themeColorsPrimaryBackgroundColorSecondary: '#b76b6a',
    themeColorsPrimaryTextColor: '#FFFFFF',
    themeColorsPrimaryTextColorSecondary: '#FFFFFF',
    themeColorsPrimaryHeroQuoteTextColor: '#FFFFFF',
    themeColorsPrimaryBorderDividerColor: '#b76b6a',
    themeColorsPrimaryButtonBackgroundColor: '#FFFFFF',
    themeColorsPrimaryButtonBackgroundColorHover: '#191717',
    themeColorsPrimaryButtonTextColor: '#a54745',
    themeColorsPrimaryButtonTextColorHover: '#FFFFFF',
    themeColorsSecondaryHeaderNavLink: '#000000',
    themeColorsSecondaryHeaderNavLinkHover: '#E3E3E3',
    themeColorsSecondaryHeaderNavLinkActive: '#E3E3E3',
    themeColorsSecondaryHeaderNavLinkBackground: '#d88933',
    themeColorsSecondaryHeaderNavLinkHoverBackground: '#d88933',
    themeColorsSecondaryHeaderNavLinkActiveBackground: '#d88933',
    themeColorsSecondaryBackgroundColor: '#d88933',
    themeColorsSecondaryBackgroundColorSecondary: '#dfa05b',
    themeColorsSecondaryTextColor: '#000000',
    themeColorsSecondaryTextColorSecondary: '#000000',
    themeColorsSecondaryHeroQuoteTextColor: '#000000',
    themeColorsSecondaryBorderDividerColor: '#dfa05b',
    themeColorsSecondaryButtonBackgroundColor: '#FFFFFF',
    themeColorsSecondaryButtonBackgroundColorHover: '#191717',
    themeColorsSecondaryButtonTextColor: '#d88933',
    themeColorsSecondaryButtonTextColorHover: '#FFFFFF',
    themeColorsTertiaryHeaderNavLink: '',
    themeColorsTertiaryHeaderNavLinkHover: '',
    themeColorsTertiaryHeaderNavLinkActive: '',
    themeColorsTertiaryHeaderNavLinkBackground: '',
    themeColorsTertiaryHeaderNavLinkHoverBackground: '',
    themeColorsTertiaryHeaderNavLinkActiveBackground: '',
    themeColorsTertiaryBackgroundColor: '#FFFFFF',
    themeColorsTertiaryBackgroundColorSecondary: '#FFFFFF',
    themeColorsTertiaryTextColor: '#000000',
    themeColorsTertiaryTextColorSecondary: '#000000',
    themeColorsTertiaryHeroQuoteTextColor: '#000000',
    themeColorsTertiaryBorderDividerColor: '',
    themeColorsTertiaryButtonBackgroundColor: '#a54745',
    themeColorsTertiaryButtonBackgroundColorHover: '#000000',
    themeColorsTertiaryButtonTextColor: '#FFFFFF',
    themeColorsTertiaryButtonTextColorHover: '#FFFFFF',
    themeColorsQuaternaryHeaderNavLink: '#FFFFFF',
    themeColorsQuaternaryHeaderNavLinkHover: '#E3E3E3',
    themeColorsQuaternaryHeaderNavLinkActive: '#E3E3E3',
    themeColorsQuaternaryHeaderNavLinkBackground: '#52675b',
    themeColorsQuaternaryHeaderNavLinkHoverBackground: '#52675b',
    themeColorsQuaternaryHeaderNavLinkActiveBackground: '#52675b',
    themeColorsQuaternaryBackgroundColor: '#52675b',
    themeColorsQuaternaryBackgroundColorSecondary: '#74857b',
    themeColorsQuaternaryTextColor: '#FFFFFF',
    themeColorsQuaternaryTextColorSecondary: '#FFFFFF',
    themeColorsQuaternaryHeroQuoteTextColor: '#FFFFFF',
    themeColorsQuaternaryBorderDividerColor: '#74857b',
    themeColorsQuaternaryButtonBackgroundColor: '#FFFFFF',
    themeColorsQuaternaryButtonBackgroundColorHover: '#000000',
    themeColorsQuaternaryButtonTextColor: '#a54745',
    themeColorsQuaternaryButtonTextColorHover: '#FFFFFF',
    themeColorsQuinaryHeaderNavLink: '#FFFFFF',
    themeColorsQuinaryHeaderNavLinkHover: '#E3E3E3',
    themeColorsQuinaryHeaderNavLinkActive: '#E3E3E3',
    themeColorsQuinaryHeaderNavLinkBackground: '#47384d',
    themeColorsQuinaryHeaderNavLinkHoverBackground: '#47384d',
    themeColorsQuinaryHeaderNavLinkActiveBackground: '#47384d',
    themeColorsQuinaryBackgroundColor: '#47384d',
    themeColorsQuinaryBackgroundColorSecondary: '#6b5f70',
    themeColorsQuinaryTextColor: '#FFFFFF',
    themeColorsQuinaryTextColorSecondary: '#FFFFFF',
    themeColorsQuinaryHeroQuoteTextColor: '#FFFFFF',
    themeColorsQuinaryBorderDividerColor: '#6b5f70',
    themeColorsQuinaryButtonBackgroundColor: '#FFFFFF',
    themeColorsQuinaryButtonBackgroundColorHover: '#E3E3E3',
    themeColorsQuinaryButtonTextColor: '#a54745',
    themeColorsQuinaryButtonTextColorHover: '#a54745',
    themeColorsSenaryHeaderNavLink: '#FFFFFF',
    themeColorsSenaryHeaderNavLinkHover: '#E3E3E3',
    themeColorsSenaryHeaderNavLinkActive: '#E3E3E3',
    themeColorsSenaryHeaderNavLinkBackground: '#4e6f8e',
    themeColorsSenaryHeaderNavLinkHoverBackground: '#4e6f8e',
    themeColorsSenaryHeaderNavLinkActiveBackground: '#4e6f8e',
    themeColorsSenaryBackgroundColor: '#4e6f8e',
    themeColorsSenaryBackgroundColorSecondary: '#718ba4',
    themeColorsSenaryTextColor: '#FFFFFF',
    themeColorsSenaryTextColorSecondary: '#FFFFFF',
    themeColorsSenaryHeroQuoteTextColor: '#FFFFFF',
    themeColorsSenaryBorderDividerColor: '#718ba4',
    themeColorsSenaryButtonBackgroundColor: '#FFFFFF',
    themeColorsSenaryButtonBackgroundColorHover: '#000000',
    themeColorsSenaryButtonTextColor: '#a54745',
    themeColorsSenaryButtonTextColorHover: '#FFFFFF',
    themeColorsSeptenaryHeaderNavLink: '',
    themeColorsSeptenaryHeaderNavLinkHover: '',
    themeColorsSeptenaryHeaderNavLinkActive: '',
    themeColorsSeptenaryHeaderNavLinkBackground: '',
    themeColorsSeptenaryHeaderNavLinkHoverBackground: '',
    themeColorsSeptenaryHeaderNavLinkActiveBackground: '',
    themeColorsSeptenaryBackgroundColor: '#000000',
    themeColorsSeptenaryBackgroundColorSecondary: '#000000',
    themeColorsSeptenaryTextColor: '#FFFFFF',
    themeColorsSeptenaryTextColorSecondary: '#FFFFFF',
    themeColorsSeptenaryHeroQuoteTextColor: '#FFFFFF',
    themeColorsSeptenaryBorderDividerColor: '',
    themeColorsSeptenaryButtonBackgroundColor: '#FFFFFF',
    themeColorsSeptenaryButtonBackgroundColorHover: '#a54745',
    themeColorsSeptenaryButtonTextColor: '#a54745',
    themeColorsSeptenaryButtonTextColorHover: '#FFFFFF',
    lightTheme: {
        actionPrimaryText: '#FFFFFF',
        actionPrimaryBackground: '#a54745',
        actionPrimaryBackgroundHover: '#191717',
        actionPrimaryBorder: 'transparent',
        actionPrimaryBorderHover: 'transparent',
        actionSecondaryText: '#FFFFFF',
        actionSecondaryBackground: '#d88933',
        actionSecondaryBackgroundHover: '#191717',
        actionSecondaryBorder: 'transparent',
        actionSecondaryBorderHover: 'transparent',
        actionTertiaryText: '#191717',
        actionTertiaryBackground: '#FFFFFF',
        actionTertiaryBackgroundHover: '#191717',
        actionTertiaryBorder: '#191717',
        actionTertiaryBorderHover: '#191717',
        actionMonochromeText: '#FFFFFF',
        actionMonochromeBackground: '#000000',
        actionMonochromeBackgroundHover: '#999999',
        actionMonochromeBorder: 'transparent',
        actionMonochromeBorderHover: 'transparent',
        actionSecondaryWhiteText: '#a54745',
        actionSecondaryWhiteTextHover: '#FFFFFF',
        actionSecondaryWhiteBackground: '#FFFFFF',
        actionSecondaryWhiteBackgroundHover: '#a54745',
        actionSecondaryWhiteBorder: 'transparent',
        actionSecondaryWhiteBorderHover: 'transparent',
        actionSecondaryBlackText: '#FFFFFF',
        actionSecondaryBlackBackground: '#191717',
        actionSecondaryBlackBackgroundHover: '#000000',
        actionSecondaryBlackBorder: 'transparent',
        actionSecondaryBlackBorderHover: 'transparent',
        actionOutlineText: '#191717',
        actionOutlineTextHover: '#FFFFFF',
        actionOutlineBackground: 'transparent',
        actionOutlineBackgroundHover: '#191717',
        actionOutlineBorder: '#D1D4D2',
        actionOutlineBorderHover: '#191717',
        actionTextLink: '#191717',
        actionTextLinkHover: '#000000',
        actionTextLinkBorder: '#E3E3E3',
        actionHeadlineLink: '#191717',
        actionHeadlineLinkHover: '#000000',
        actionNavLink: '#191717',
        actionNavLinkBackground: '#FFFFFF',
        actionNavLinkHover: '#a54745',
        actionNavLinkHoverBackground: '#FFFFFF',
        actionNavLinkActive: '#F5F4F1',
        actionNavLinkActiveBackground: '#191717',
        actionDisabledText: '#FFFFFF',
        actionDisabledBackground: '#D1D4D2',
        actionDisabledBackgroundHover: '#D1D4D2',
        actionDisabledBorder: '#D1D4D2',
        actionDisabledBorderHover: '#D1D4D2',
        actionRadioSquareBackground: '#FFFFFF',
        actionRadioSquareActiveBackground: '#191717',
        actionRadioSquareColor: '#191717',
        actionRadioSquareActiveColor: '#FFFFFF',
        textTitle: '#191717',
        textHeadline: '#191717',
        textSubHeadline: '#191717',
        textTitleOverlay: '#FFFFFF',
        textBody: '#191717',
        textCaption: '#191717',
        textPrice: '#191717',
        formInputLabelText: '#191717',
        formInputFocusLabelText: '#191717',
        formInputInputText: '#191717',
        formInputInputTextDisabled: '#999999',
        formInputPlaceholderText: '#4F4F4F',
        formInputBorder: '#D1D4D2',
        formInputBorderFocus: '#A8A8A8',
        formInputBorderError: '#c23934',
        formInputCheckbox: '#007B4D',
        formInputBackground: '#FFFFFF',
        formInputBackgroundSecondary: '#FFFFFF',
        formInputBorderSecondary: '#E3E3E3',
        formInputBackgroundDisabled: '#F5F4F1',
        formRadioEnabled: '#000000',
        formRadioDisabled: '#D1D4D2',
        formRadioBorderDisabled: '#E3E3E3',
        formRadioBorderEnabled: '#D1D4D2',
        formRadioBorderActive: '#000000',
        formListItemBackground: '#F5F4F1',
        formListItemBackgroundHover: '#f8f8f8',
        formSliderVerticalBars: '#D1D4D2',
        formSliderHandleBackground: '#FFFFFF',
        formSliderHandleBorder: '#D1D4D2',
        formSliderProgressBar: '#4e6f8e',
        formStatusErrorLight: '#FEF3F2',
        formStatusErrorMain: '#c23934',
        formStatusErrorDark: '#AE201A',
        formStatusSuccessLight: '#F0FDF5',
        formStatusSuccessMain: '#21A153',
        formStatusSuccessDark: '#21A153',
        formStatusWarningLight: '#FEFCE9',
        formStatusWarningMain: '#ffb75d',
        formStatusWarningDark: '#975A12',
        formStatusInfoLight: '#EFF6FF',
        formStatusInfoMain: '#0073BE',
        formStatusInfoDark: '#1244CA',
        headerBackground: '#FFFFFF',
        headerStickyBorderBottom: 'rgba(189, 189, 189, 0.40)',
        headerStickyBoxShadow: 'transparant',
        headerStickyBoxShadowNoNotification: 'transparant',
        headerStickyBackground: '#FFFFFF',
        headerStickyBackgroundScrolled: '#FFFFFF',
        headerStickyBorderBottomScrolled: 'rgba(189, 189, 189, 0.40)',
        headerSearchInputBorder: '#D1D4D2',
        headerMegamenu: '#f8f8f8',
        headerPlpSidebarTitle: '#191717',
        footerBackground: '#a54745',
        footerTextColor: '#FFFFFF',
        footerHeadingColor: '#FFFFFF',
        tableThead: '#F5F4F1',
        tableTh: '#F5F4F1',
        tableTd: '#FFFFFF',
        iconPrimary: '#F5F4F1',
        iconSecondary: '#999999',
        iconTertiary: '#000000',
        iconLoader: '#FFFFFF',
        iconRoundedBackground: '#F5F4F1',
        iconRoundedIcon: '#000000',
        iconRoundedBorder: '#000000',
        iconRoundedActiveBackground: '#000000',
        iconRoundedActiveIcon: '#F5F4F1',
        iconRoundedActiveBorder: '#000000',
        logo: '#a54745',
        borderDivider1: '#F5F4F1',
        borderDivider2: '#E3E3E3',
        pillActiveTextColor: '#FFFFFF',
        pillActiveTextColorHover: '#FFFFFF',
        pillActiveBorderColor: '#000000',
        pillActiveBorderColorHover: '#000000',
        pillActiveBackground: '#000000',
        pillActiveBackgroundHover: '#4F4F4F',
        pillDefaultTextColor: '#191717',
        pillDefaultTextColorHover: '#FFFFFF',
        pillDefaultBorderColor: '#D1D4D2',
        pillDefaultBorderColorHover: '#191717',
        pillDefaultBackground: '#FFFFFF',
        pillDefaultBackgroundHover: '#191717',
        pillBadgeBackground: '#191717',
        pillBadgeBorderColorHover: '#191717',
        wrapperBody: '#FFFFFF',
        wrapperPublication: 'undefined',
        wrapperCategory: 'undefined',
        wrapperProduct: 'undefined',
        wrapperTileColor: '#F5F4F1',
        wrapperTileColorHover: '#F5F4F1',
        wrapperSection: '#FFFFFF',
        wrapperBodyHover: '#F5F4F1',
        wrapperWell: '#FFFFFF',
        wrapperCard: '#f8f8f8',
        wrapperCardHover: '#F5F4F1',
        wrapperCardGradientColor: '#f8f8f8',
        wrapperCardGradientColorHover: '#F5F4F1',
        wrapperCardBorderColor: 'transparent',
        wrapperModal: '#A8A8A8',
        wrapperBanner: '#FFFFFF',
        wrapperBannerBorder: '#FFFFFF',
        wrapperUsp: '#000000',
        wrapperUspColor: '#FFFFFF',
        oBikeComparisonCompareButtonBackground: '#f8f8f8',
        oBikeComparisonCompareButtonBorderColor: '#999999',
        uListItemHighlightColor: '#a54745',
        uListItemTextColorHover: '#a54745',
        uCarouselBackgroundColor: 'unset',
        uPullUpToggleBackground: '#191717',
        uNewsletterTitleColor: '#FFFFFF',
        uKeyFeatureListBackgroundColor: 'unset',
        fallbackWhite: '#FFFFFF',
        fallbackGrey50: '#F5F4F1',
        fallbackGrey100: '#F5F4F1',
        fallbackGrey200: '#E3E3E3',
        fallbackGrey300: '#D1D4D2',
        fallbackGrey400: '#A8A8A8',
        fallbackGrey500: '#999999',
        fallbackGrey600: '#4F4F4F',
        fallbackGrey700: '#000000',
        fallbackGrey750: '#000000',
        fallbackGrey800: '#000000',
        fallbackGrey900: '#000000',
        fallbackBlack: '#191717',
    },
    darkTheme: {
        actionPrimaryText: '#a54745',
        actionPrimaryTextHover: '#FFFFFF',
        actionPrimaryBackground: '#FFFFFF',
        actionPrimaryBackgroundHover: '#a54745',
        actionPrimaryBorder: 'transparent',
        actionPrimaryBorderHover: 'transparent',
        actionSecondaryText: '#d88933',
        actionSecondaryTextHover: '#FFFFFF',
        actionSecondaryBackground: '#FFFFFF',
        actionSecondaryBackgroundHover: '#d88933',
        actionSecondaryBorder: 'transparent',
        actionSecondaryBorderHover: 'transparent',
        actionTertiaryText: '#FFFFFF',
        actionTertiaryTextHover: '#191717',
        actionTertiaryBackground: 'transparent',
        actionTertiaryBackgroundHover: '#FFFFFF',
        actionTertiaryBorder: '#FFFFFF',
        actionTertiaryBorderHover: '#FFFFFF',
        actionMonochromeText: '#FFFFFF',
        actionMonochromeBackground: '#000000',
        actionMonochromeBackgroundHover: '#999999',
        actionMonochromeBorder: 'transparent',
        actionMonochromeBorderHover: 'transparent',
        actionSecondaryWhiteText: '#d88933',
        actionSecondaryWhiteTextHover: '#FFFFFF',
        actionSecondaryWhiteBackground: '#FFFFFF',
        actionSecondaryWhiteBackgroundHover: '#d88933',
        actionSecondaryWhiteBorder: 'transparent',
        actionSecondaryWhiteBorderHover: 'transparent',
        actionSecondaryBlackText: '#FFFFFF',
        actionSecondaryBlackBackground: '#191717',
        actionSecondaryBlackBackgroundHover: '#000000',
        actionSecondaryBlackBorder: 'transparent',
        actionSecondaryBlackBorderHover: 'transparent',
        actionOutlineText: '#FFFFFF',
        actionOutlineTextHover: '#000000',
        actionOutlineBackground: 'transparent',
        actionOutlineBackgroundHover: 'transparent',
        actionOutlineBorder: '#D1D4D2',
        actionOutlineBorderHover: '#A8A8A8',
        actionTextLink: '#FFFFFF',
        actionTextLinkHover: '#000000',
        actionTextLinkBorder: '#E3E3E3',
        actionHeadlineLink: '#191717',
        actionHeadlineLinkHover: '#000000',
        actionNavLink: '#191717',
        actionNavLinkBackground: '#FFFFFF',
        actionNavLinkHover: '#191717',
        actionNavLinkHoverBackground: '#F5F4F1',
        actionNavLinkActive: '#F5F4F1',
        actionNavLinkActiveBackground: '#191717',
        actionDisabledText: '#FFFFFF',
        actionDisabledBackground: '#D1D4D2',
        actionDisabledBackgroundHover: '#D1D4D2',
        actionDisabledBorder: '#D1D4D2',
        actionDisabledBorderHover: '#D1D4D2',
        actionRadioSquareBackground: '#FFFFFF',
        actionRadioSquareActiveBackground: '#191717',
        actionRadioSquareColor: '#191717',
        actionRadioSquareActiveColor: '#FFFFFF',
        textTitle: '#FFFFFF',
        textHeadline: '#FFFFFF',
        textSubHeadline: '#FFFFFF',
        textTitleOverlay: '#FFFFFF',
        textBody: '#FFFFFF',
        textCaption: '#FFFFFF',
        textPrice: '#FFFFFF',
        formInputLabelText: '#191717',
        formInputFocusLabelText: '#191717',
        formInputInputText: '#191717',
        formInputPlaceholderText: '#4F4F4F',
        formInputBorder: '#D1D4D2',
        formInputBorderFocus: '#A8A8A8',
        formInputBorderError: '#c23934',
        formInputCheckbox: '#007B4D',
        formInputBackground: '#FFFFFF',
        formInputBackgroundSecondary: '#FFFFFF',
        formInputBorderSecondary: '#E3E3E3',
        formInputBackgroundDisabled: '#F5F4F1',
        formRadioEnabled: '#000000',
        formRadioDisabled: '#D1D4D2',
        formRadioBorderDisabled: '#E3E3E3',
        formRadioBorderEnabled: '#D1D4D2',
        formRadioBorderActive: '#000000',
        formListItemBackground: '#F5F4F1',
        formListItemBackgroundHover: '#f8f8f8',
        formSliderVerticalBars: '#D1D4D2',
        formSliderHandleBackground: '#FFFFFF',
        formSliderHandleBorder: '#D1D4D2',
        formSliderProgressBar: '#4e6f8e',
        formStatusErrorLight: '#FEF3F2',
        formStatusErrorMain: '#c23934',
        formStatusErrorDark: '#AE201A',
        formStatusSuccessLight: '#F0FDF5',
        formStatusSuccessMain: '#21A153',
        formStatusSuccessDark: '#21A153',
        formStatusWarningLight: '#FEFCE9',
        formStatusWarningMain: '#ffb75d',
        formStatusWarningDark: '#975A12',
        formStatusInfoLight: '#EFF6FF',
        formStatusInfoMain: '#0073BE',
        formStatusInfoDark: '#1244CA',
        headerBackground: '#FFFFFF',
        headerStickyBorderBottom: 'rgba(189, 189, 189, 0.40)',
        headerStickyBoxShadow: 'transparant',
        headerStickyBoxShadowNoNotification: 'transparant',
        headerStickyBackground: '#FFFFFF',
        headerStickyBackgroundScrolled: '#FFFFFF',
        headerStickyBorderBottomScrolled: 'rgba(189, 189, 189, 0.40)',
        headerSearchInputBorder: '#D1D4D2',
        headerMegamenu: '#FFFFFF',
        headerPlpSidebarTitle: '#FFFFFF',
        footerBackground: '#a54745',
        footerTextColor: '#FFFFFF',
        footerHeadingColor: '',
        tableThead: '#F5F4F1',
        tableTh: '#F5F4F1',
        tableTd: '#FFFFFF',
        iconPrimary: '#F5F4F1',
        iconSecondary: '#999999',
        iconTertiary: '#000000',
        iconLoader: '#FFFFFF',
        iconRoundedBackground: '#F5F4F1',
        iconRoundedIcon: '#000000',
        iconRoundedBorder: '#000000',
        iconRoundedActiveBackground: '#000000',
        iconRoundedActiveIcon: '#F5F4F1',
        iconRoundedActiveBorder: '#000000',
        logo: '#a54745',
        borderDivider1: '#F5F4F1',
        borderDivider2: '#E3E3E3',
        pillActiveTextColor: '#FFFFFF',
        pillActiveTextColorHover: '#FFFFFF',
        pillActiveBorderColor: '#000000',
        pillActiveBorderColorHover: '#000000',
        pillActiveBackground: '#000000',
        pillActiveBackgroundHover: '#4F4F4F',
        pillDefaultTextColor: '#000000',
        pillDefaultTextColorHover: '#FFFFFF',
        pillDefaultBorderColor: '#D1D4D2',
        pillDefaultBorderColorHover: '#191717',
        pillDefaultBackground: '#FFFFFF',
        pillDefaultBackgroundHover: '#191717',
        pillBadgeBackground: '#a54745',
        pillBadgeBorderColorHover: '#191717',
        wrapperBody: '#191717',
        wrapperPublication: 'undefined',
        wrapperCategory: 'undefined',
        wrapperProduct: 'undefined',
        wrapperTileColor: '#F5F4F1',
        wrapperTileColorHover: '#F5F4F1',
        wrapperSection: '#191717',
        wrapperBodyHover: '#F5F4F1',
        wrapperWell: '#191717',
        wrapperCard: '#F5F4F1',
        wrapperCardHover: '#F5F4F1',
        wrapperCardGradientColor: '#F5F4F1',
        wrapperCardGradientColorHover: '#F5F4F1',
        wrapperCardBorderColor: 'transparent',
        wrapperModal: '#A8A8A8',
        wrapperBanner: '#191717',
        wrapperBannerBorder: '#191717',
        wrapperUsp: '#000000',
        wrapperUspColor: '#FFFFFF',
        oBikeComparisonCompareButtonBackground: '#F5F4F1',
        oBikeComparisonCompareButtonBorderColor: '#D1D4D2',
        uListItemHighlightColor: '#a54745',
        uListItemTextColorHover: '#a54745',
        uCarouselBackgroundColor: 'unset',
        uPullUpToggleBackground: '#191717',
        uNewsletterTitleColor: '#FFFFFF',
        uKeyFeatureListBackgroundColor: 'unset',
        fallbackWhite: '#FFFFFF',
        fallbackGrey50: '#F5F4F1',
        fallbackGrey100: '#F5F4F1',
        fallbackGrey200: '#E3E3E3',
        fallbackGrey300: '#D1D4D2',
        fallbackGrey400: '#A8A8A8',
        fallbackGrey500: '#999999',
        fallbackGrey600: '#4F4F4F',
        fallbackGrey700: '#000000',
        fallbackGrey750: '#000000',
        fallbackGrey800: '#000000',
        fallbackGrey900: '#000000',
        fallbackBlack: '#191717',
    },
};
